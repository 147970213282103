// TEST
console.log("main script working");
// MAIN JAVASCRIPT

// Firebase Services
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push, set } from "firebase/database";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyANYE2i8zYBoiv8sbdpKBR63cvlgWAZJLk",
  authDomain: "capetonian-poet.firebaseapp.com",
  databaseURL: "https://capetonian-poet-default-rtdb.firebaseio.com",
  projectId: "capetonian-poet",
  storageBucket: "capetonian-poet.firebasestorage.app",
  messagingSenderId: "983700192749",
  appId: "1:983700192749:web:27f8cbda79d9a5006bfbe4",
  measurementId: "G-FQGDFG27PQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

// SUBSCRIBE FORM
document.getElementById("form-data").addEventListener("submit", submitForm);

// Submit form
function submitForm(e) {
  e.preventDefault();

  // Get Values
  const name = getInputVal("name");
  const email = getInputVal("email");

  // console.log(123);

  // Save Message
  saveMessage(name, email);

  // Show Alert
  document.querySelector(".alert").style.display = "block";

  // Hide Alert after 3 seconds
  setTimeout(function () {
    document.querySelector(".alert").style.display = "none";
  }, 10000);

  // Clear form
  document.getElementById("form-data").reset();
}

// Function to get form values
function getInputVal(id) {
  return document.getElementById(id).value;
}

//Save message to firebabse
function saveMessage(name, email) {
  const db = getDatabase();
  const messagesRef = ref(db, "subscribers");
  const newMessageRef = push(messagesRef);
  set(newMessageRef, {
    username: name,
    email: email,
  });
}
